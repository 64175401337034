import Layout from './Layout';
import { Login } from './Login';
import { NewProject } from './NewProject';
import { Command } from './Command';
import { Agreement } from './Agreement';
import { Kpis } from './Kpis';
import { Modal } from './Modal';
import { Sidebar } from "./Sidebar";
import { Toast } from "./Toast";
import { ProjectHistory } from "@/reducers/ProjectHistory";
import Dictionaries from './Dictionaries';
import { Required } from "@/reducers/Required";
import { UserStorage } from '@/reducers/UserStorage';
import { AppMessage } from '@/reducers/AppMessage';
import {MetricaSrc} from "@/reducers/MetricaSrc";

const Reducers = {
  NewProject,
  Login,
  Layout,
  Command,
  Agreement,
  Kpis,
  Modal,
  Sidebar,
  Toast,
  AppMessage,
  ProjectHistory,
  Required,
  UserStorage,
  dict: Dictionaries,
  MetricaSrc,
};

export default Reducers;
