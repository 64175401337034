import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useExtImport } from '@/utils/hooks';

const Auth = lazy(() => import('../Auth'));
const Layout = lazy(() => import('../Main/Layout'));
const InitReport = lazy(() => import('../CreateProject/ProjectReport/InitReport'));
const CloseReport = lazy(() => import('../CreateProject/ProjectReport/CloseReport'));
const TopReport = lazy(() => import('../TopReport'));

const AppRoutes = () => {
  const extModule = useExtImport('pages/Main/AppRoutesExt');

  return (
    <Routes>
      <Route path="/auth/*"
             element={<Auth />} />

      <Route path="/portfolio/:id/init-report/*"
             element={<InitReport />} />
      <Route path="/program/:id/init-report/*"
             element={<InitReport />} />
      <Route path="/project/:id/init-report/*"
             element={<InitReport />} />

      <Route path="/portfolio/:id/close-report/*"
             element={<CloseReport />} />
      <Route path="/program/:id/close-report/*"
             element={<CloseReport />} />
      <Route path="/project/:id/close-report/*"
             element={<CloseReport />} />

      <Route path="/topReport/*"
             element={<TopReport type='TOP' />} />
      <Route path="/keyReport/*"
             element={<TopReport type='KEY' />} />

      <Route path="*"
             element={<Layout />} />
      {extModule}
    </Routes>
  );
};

export default AppRoutes;