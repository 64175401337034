import React from 'react';
import moment from 'moment'
import { capitalize, groupBy, first, last, isFunction } from 'lodash';
import { ArgumentAxis } from '@devexpress/dx-react-chart-bootstrap4';
import { isEmptyValues } from '@/utils';

const DAY = 'day';
const DAY_SHORT = 'day short';
const TWO_WEEK = 'two week';
const WEEK = 'week';
const WEEK_SHORT = 'week short';
const MONTH = 'month';
const MONTH_SHORT = 'month short';
const MONTH_YEAR = 'month year';
const MONTH_YEAR_SHORT = 'month year short';
const QUARTER = 'quarter';
const HALF_YEAR = 'half year';
const YEAR = 'year';

const compare = (
  { series, point }, { series: targetSeries, point: targetPoint },
) => series === targetSeries && point === targetPoint;

const createSelectorChart = ([val, setter]) => [
  val,
  ({ targets }) => {
    const target = targets[0];
    if (target) {
      setter(val[0] && compare(val[0], target) ? [] : [target]);
    } else {
      setter([]);
    }
  }
];

const changeFilterOnClick = (selection, dataCurrent, setFilter, setLocalData) => {
  const isSelect = !!selection.length
  const select = selection[0];
  const point = select ? dataCurrent[select.point] : undefined;

  setLocalData(isSelect ? dataCurrent : null);
  setFilter(point);
}

const getDataProcent = (data, field = 'cntNow') => {
  const dataSum = data.reduce((acc, item) => acc + item[field], 0);
  return data.map(item => ({
    ...item,
    cntNowProcent: Math.round(item[field] / dataSum * 100)
  })).filter(item => item.cntNowProcent);
}

const updateChartDataFunc = (filterValue, chartName, func) => ({
  ...filterValue,
  chart: {
    ...filterValue.chart,
    [chartName]: {
      ...filterValue.chart[chartName],
      ...(func(filterValue.chart[chartName]))
    },
    filterApply: randomNumber(),
  }
});

const updateFilter = (filterOnChange) =>
  (key, value) =>
    filterOnChange(prev => ({
      ...prev,
      [key]: value
    }))

const updateChartData = (filterValue, chartName, key, value) =>
  updateChartDataFunc(
    filterValue,
    chartName,
    () => ({
      [key]: value
    })
  );

const createChartComputed = (filterValue, filterOnChange, name, key) => ([
  filterValue.chart[name][key],
  (value) => filterOnChange((prev) => updateChartData(prev, name, key, value))
])

const formatDateMap = {
  [DAY]: "D MMM",
  [DAY_SHORT]: "DD.MM",
  [WEEK]: "D MMM",
  [WEEK_SHORT]: "DD.MM",
  [MONTH]: "MMMM",
  [MONTH_SHORT]: "MMM",
  [MONTH_YEAR]: "MMMM YYYY",
  [MONTH_YEAR_SHORT]: "MMM.YY",
  [YEAR]: "YYYY",
  [TWO_WEEK]: (date, isShort = false) => capitalize(date.format("MMM").replace('.', '')) + `${isShort ? '' : "/" + (date.date() < 15 ? 1 : 2)}`
}

const formatDateMapTooltip = {
  [DAY]: "D MMM YYYY",
  [WEEK]: "D MMMM YYYY",
  [MONTH]: "MMMM YYYY",
  [YEAR]: "YYYY",
  [TWO_WEEK]: (date, isShort = false) => {
    // в D MMMM день обрезается специально, нужен он что бы moment правильно склонял месяц
    return (date.date() < 15 ? '1/2' : '2/2') + " " + date.format(isShort ? "D MMM" : "D MMMM").replace(/\d+\s/, '') + " " + date.year();
  }
}

const formatDateGraphTooltip = (type, date, shiftDays = undefined) => {
  return formatDateGraph(type, date, shiftDays, formatDateMapTooltip);
}

const formatDateGraph = (type, date, shiftDays = 0, formatter: any = formatDateMap, isShort = false) => {
  if (type === DAY) {
    return moment(date).format(formatter[isShort ? DAY_SHORT : DAY]);
  }

  if (type === WEEK) {
    return moment(date).weekday(shiftDays).format(formatter[isShort ? WEEK_SHORT : WEEK]);
  }

  if (type === TWO_WEEK) {
    const dateMoment = moment(date).weekday(shiftDays);

    return isFunction(formatter[TWO_WEEK]) 
      ? formatter[TWO_WEEK](dateMoment, isShort) 
      : dateMoment.format(formatter[TWO_WEEK])
  }

  if (type === MONTH) {
    return capitalize(moment(date).format(formatter[isShort ? MONTH_SHORT : MONTH]));
  }

  if (type === MONTH_YEAR) {
    return capitalize(moment(date).format(formatter[isShort ? MONTH_YEAR_SHORT : MONTH_YEAR]));
  }

  if (type === QUARTER) {
    const momentDate = moment(date);

    if (formatter[QUARTER]) {
      return momentDate.format(formatter[QUARTER]);
    }

    return `${momentDate.quarter()} ’${momentDate.format('YY')}`;
  }

  if (type === HALF_YEAR) {
    const momentDate = moment(date);

    if (formatter[HALF_YEAR]) {
      return momentDate.format(formatter[HALF_YEAR]);
    }

    return `${momentDate.month() <= 6 ? 1 : 2} ’${momentDate.format('YY')}`;
  }

  if (type === YEAR) {
    return moment(date).format(formatter[YEAR]);
  }

  return '-'
}

const toDateCalendar = (date) => date ? ({
  day: date.date(),
  month: date.month() + 1,
  year: date.year()
}) : ({
  day: null,
  month: null,
  year: null,
})

const dateCalendarToMoment = (date) => date
  ? moment([date.year, date.month - 1, date.day])
  : null

const reportColors = [
  {
    name: "По плану",
    key: "PLAN",
    color: "#66e59b",
    id: 1,
    keyDown: 'Plan',
    desc: undefined,
  },
  {
    name: "Незначительное отклонение",
    key: "SLIGHT_DEVIATION",
    color: "#ffd36b",
    id: 2,
    keyDown: 'SlightDeviation',
    desc: undefined,
  },
  {
    name: "Значительное отклонение",
    key: "SIGNIFICANT_DEVIATION",
    color: "#ff6d6d",
    id: 3,
    keyDown: 'SignificantDeviation',
    desc: undefined,
  },
];

const getReportColors = (data) =>
  data.map(({ statusCode }) =>
    reportColors.find(({ key }) => statusCode === key)?.color);

const groupData = (data: any[]) =>
  Object.entries(groupBy<any>(data, ({ dateFormated }) => dateFormated))
    .map(([key, value]: any) => ({
      ...value[0],
      val: value.reduce((acc, { val }) => acc + val, 0) / value.length
    }))


const createArgumentLabelComponent = (data, max = 9, accessField = undefined, formatter = (label) => label,
                                      style: React.CSSProperties = {}) => {
  const filterLabel = (data) => data.length > max
    ? filterLabel(data.filter((_, i, arr) => i % 2 === 0))
    : data;

  const showLabel = filterLabel(data).map(item => formatter(accessField ? item[accessField] : item.dateFormated));

  return props => {
    if (!showLabel.includes(props.text)) {
      return null;
    }

    return (
      <ArgumentAxis.Label {...props} style={style}/>
    )
  };
}

const strDateComparator = (date1, date2) => {
  if (date1 === null) {
    return 1;
  }

  if (date2 === null) {
    return -1;
  }

  const momentA = dateCalendarToMoment(date1);
  const momentB = dateCalendarToMoment(date2);
  if (momentA > momentB) return 1;
  else if (momentA < momentB) return -1;
  else return 0;
}

const strSortDate = (arr) => {
  return arr.slice(0).sort(strDateComparator);
}

const calcType = (date, type) => {
  const dateMoment = date.clone();

  if (type === TWO_WEEK) {
    dateMoment.add(2, 'week');
  } else {
    dateMoment.add(1, type);
  }

  return dateMoment.add(-1, 'second');
}

const calcToDate = (date, type, dateMax) => {
  if (!date) {
    return {
      from: null,
      to: null
    };
  }

  const dateMoment = moment(date);
  const to = calcType(dateMoment, type);

  return {
    from: last(strSortDate([
      {
        day: dateMoment.date(),
        month: dateMoment.month() + 1,
        year: dateMoment.year()
      },
      dateMax.from
    ])),
    to: first(strSortDate([
      {
        day: to.date(),
        month: to.month() + 1,
        year: to.year()
      },
      dateMax.to
    ]))
  }
}

const glitchSort = (arr) => arr.length <= 2 ? arr : [arr[0], arr[arr.length - 1], ...glitchSort(arr.slice(1, -1))]

const formatMuiltiline = (cell: string | string[]) => {
  const data = (typeof cell) === 'string' ? (cell as string).split('\n') : (cell as string[])

  if (isEmptyValues(data)) {
    return null;
  }

  return data.map((item, i) => (
    <div key={i} className="table-multiline-item">{item}</div>
  ));
}

const randomNumber = () => Math.round(Math.random() * 1000);

export {
  DAY,
  WEEK,
  TWO_WEEK,
  MONTH,
  QUARTER,
  HALF_YEAR,
  YEAR,
  createSelectorChart,
  calcToDate,
  getDataProcent,
  changeFilterOnClick,
  updateFilter,
  updateChartDataFunc,
  updateChartData,
  formatDateGraphTooltip,
  formatDateGraph,
  createChartComputed,
  glitchSort,
  reportColors,
  getReportColors,
  createArgumentLabelComponent,
  groupData,
  toDateCalendar,
  dateCalendarToMoment,
  formatMuiltiline,
  randomNumber
}
