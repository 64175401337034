interface IMetricaSrc {
    metricaSrc: string
}

export const initialState = ({
    metricaSrc: ``,
} as IMetricaSrc);

export const MetricaSrc = (state = initialState, action: any): IMetricaSrc => {
    switch (action.type) {
        case 'SET_NEW_METRICA_SRC':
            return {metricaSrc: action.payload};
        default:
            return state;
    }
};
