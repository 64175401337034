import { importExt } from '@/utils';

export const getAllFelixDict = async () => {
  const extDict = await importExt('store/dictExt') || [];
  return felixDict.concat(extDict);
}

export const felixDict = [
  'status',
  'stage',
  'types',
  'projectTypes',
  'segment',
  'roles',
  'roleTypes',
  'rights',
  'userStatus',
  'riskProbability',
  'riskPriority',
  'riskAffects',
  'riskStatus',
  'cfo',
  'workStatus',
  'kpiType',
  'kpiStatus',
  'mboStatus',
  'mboGoalType',
  'mboQuarter',
  'fileSection',
  'fileType',
  'reportStatus',
  'properties',
  'lessonKnowledge',
  'region',
  'reportCloseGrade',
  'committeeType',
  'committeeStatus',
  'committeeObject',
  'agreeStatus',
  'agreeUserStatus',
  'agreeSection',
  'agreeStage',
  'businessCaseIndex',
  'businessCaseType',
  'businessCaseStatus',
  'scale',
  'businessCaseCategory',
  'budgetSourceType',
  'budgetType',
  'projectImplementationEvaluation',
  'holidays',
  'externalCustomer',
  'employeeCfo',
  'budgetDirection',
  'budgetSection',
  'leaderRating',
  'scaleRating',
  'meetingStatus',
  'meetingUserStatus',
  'keyEventStatus',
  'workLinkType',
  'timesheetStatus',
  'workType',
  'certificateRating',
  'assignmentStatus',
  'assignmentAgreeStatus',
  'assignmentAgreeType',
  'projectRiskType',
  'projectRiskStrategyThreat',
  'projectRiskStrategyOpportunity',
  'budgetSourceInputType',
  'settingsType',
  'businessProcess',
  'reportAgreeStatus',
  'searchFiredMode',
  'projectClass',
  'projectWorkAgreeType',
  'kpiCascadeType',
  'geography',
  'committeeProjectStatus'
];